import React from "react";
import Layout from "@p14/layouts";
import styled from "styled-components";
import * as Components from "@p14/components";
import config from "@p14/config";

export const Container = styled.section`
  display: flex;
  max-width: 870px;
  margin: 0 auto;

  @media ${config.media.mobile} {
    flex-direction: column;
  }
`;

export const Item = styled.article`
  @media ${config.media.desktopOnly} {
    width: ${(props) => (props.size === "xl" ? "619px" : "400px")};
    margin-bottom: 80px;

    &:nth-child(2) {
      margin-left: 70px;
    }
  }

  @media ${config.media.tabletOnly} {
    flex: ${(props) => (props.size === "xl" ? "0 0 577px" : "1 1 283px")};
    margin-bottom: 80px;

    &:nth-child(2) {
      margin-left: 70px;
    }
  }

  @media ${config.media.mobile} {
    width: 100%;
    margin-bottom: 60px;
  }
`;

export const Title = styled.h2`
  margin: 0 0 10px;
  font-size: 14px;
  color: ${config.colors.main};
  letter-spacing: 0.12px;
  font-family: ${config.fonts.serif};
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
`;

export const Figure = styled.figure`
  margin: 0;

  @media ${config.media.mobile} {
    margin: ${(props) => (props.size === "xl" ? "0 -20px" : 0)};
  }
`;

export const Image = styled.img`
  max-width: 100%;
  margin-bottom: 10px;

  @media ${config.media.mobile} {
    width: 100%;
    object-fit: cover;
    height: ${(props) => (props.size === "xl" ? "300px" : "auto")};
  }
`;

export const Caption = styled.figcaption`
  margin: 0 0 20px;
  text-align: center;
  font-family: ${config.fonts.sansSerif};
  font-size: 14px;
  color: ${config.colors.main};
  line-height: 21px;
  text-transform: uppercase;

  @media ${config.media.tabletOnly} {
    text-align: ${(props) => (props.size === "xl" ? "center" : "left")};
  }
`;

export const Text = styled.p`
  font-family: ${config.fonts.sansSerif};
  color: ${config.colors.text};
  font-size: 14px;
  margin: 0;

  @media ${config.media.tabletOnly} {
    padding-left: ${(props) => (props.size === "xl" ? "0" : "33px")};
  }
`;

export const articles = [
  {
    title: "архитектор",
    image: "/static/team/arch.jpg",
    caption: "GREEN DESIGN SOCIETY",
    text: "Основатели бюро Ник Батлер и Михол Гроган – перфекционисты, которые на первый план ставят эргономику, долговечность и функциональность здания и много внимания уделяют тому, чтобы гармонично вписать архитектуру в контекст. Больше 20 лет работают в России, а до основания Green Design отвечали за крупные проекты в международной архитектурной компании – от частных вилл до отелей и&nbsp;аэропорта.",
  },
  {
    title: "Дизайн и отделка",
    image: "/static/team/designers.jpg",
    caption: "Hesse Macken Studio",
    text: "До&nbsp;того как основать собственную студию, Уиллоу и&nbsp;Эндрю работали в&nbsp;знаковых для Лондона дизайн-бюро и&nbsp;выработали узнаваемый почерк: любовь к&nbsp;чистоте и&nbsp;характеру натуральных материалов, виртуозное использование деталей и&nbsp;умение сделать даже самый роскошный интерьер располагающим и&nbsp;уютным. Считают, что все начинается с&nbsp;материалов, и&nbsp;не&nbsp;жалеют времени на&nbsp;поиски: например, разработали собственный оттенок для дубового пола, потому что идеального не&nbsp;нашлось.",
  },
  {
    title: "застройщик",
    image: "/static/team/developers.jpg",
    caption: "Elbert Development Group",
    text: "&laquo;Элберт Девелопмент групп&raquo; основана в&nbsp;1988 году Олегом Аксельродом. За&nbsp;время&nbsp;работы компании построены такие проекты, как Вересаева 31, Ефремова 14, Весковский переулок 2, Петровский Дом на&nbsp;улице Серегина. С&nbsp;2003 года к&nbsp;компании присоединилась дочь Олега Ольга, жительница Нью-Йорка и&nbsp;выпускница Barnard College в&nbsp;Columbia University. &laquo;Мы&nbsp;хотим сделать дом-сообщество для людей, которые много путешествовали, много видели и&nbsp;задавались вопросом: а&nbsp;возможно&nbsp;ли жильё такого класса в&nbsp;Москве? Проект прозрачный и&nbsp;понятный: мы&nbsp;показываем все спецификации, чтобы покупатель понимал, что вложено в&nbsp;здание. Строить на&nbsp;века сейчас не&nbsp;принято, но&nbsp;мы&nbsp;решили изменить это.",
  },
];

export const Article = ({ title, image, caption, text, size }) => (
  <Item size={size}>
    <Title>{title}</Title>
    <Figure size={size}>
      <Image src={image} size={size} />
      <Caption size={size}>{caption}</Caption>
    </Figure>
    <Text size={size} dangerouslySetInnerHTML={{ __html: text }} />
  </Item>
);

export const Description = styled.p`
  margin: 0 auto 20px;
  max-width: 310px;
  font-family: Geometria;
  font-size: 14px;
  color: #000;

  &:last-child {
    margin-bottom: 100px;
  }
`;

export const Logo = styled.img`
  display: block;
  max-width: 188px;
  margin: 35px auto 50px;
`;

export const partners = [
  {
    title: "MIELE",
    img: "/static/partners/miele.png",
    url: "https://www.miele.ru",
  },
  {
    title: "DORN BRACHT",
    img: "/static/partners/dorn-bracht.png",
    url: "http://www.dornbracht.com",
  },
  {
    title: "DURAVIT",
    img: "/static/partners/duravit.png",
    url: "https://www.duravit.com/",
  },
  {
    title: "PRO TRENER",
    img: "/static/partners/pro-trener.png",
    url: "http://pro-trener.ru/",
  },
  {
    title: "TECHNO GYM",
    img: "/static/partners/techno-gym.png",
    url: "https://www.technogym.com/",
  },
  {
    title: "ALEKSANDR NEY",
    img: "/static/partners/ney.png",
    url: "https://ney.ru/",
  },
  {
    title: "KRASSKY",
    img: "/static/partners/krassky.png",
    url: "https://krassky.ru",
  },
];

export const Partner = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  flex: 0 0 14.285714285714286%;
  max-width: 14.285714285714286%;
  margin: 20px 0;
  padding: 0 8px;
  transition: opacity 0.3s ease 0s;
  will-change: opacity;

  & img {
    max-width: 100%;
  }

  &:hover {
    opacity: 0.7;
  }

  @media ${config.media.mobile} {
    max-width: 100%;
  }
`;

export const meta = {
  title: "Команда проекта «Пироговская, 14» в Хамовниках.",
  keywords:
    "Пироговская, 14,  Хамовники, команда, кто строит, Hesse Macken, Green design society, Элберт Девелопмент групп, Strabag SE",
  description:
    "Для реализации проекта Элберт Девелопмент собрали звездную команду: архитектурное бюро Green design society, дизайнеров интерьера и экстерьера Hesse Macken Studio, реализацией строительства занимается всемирно известная корпорация  Strabag SE.",
};

export const TeamScreen = () => (
  <Layout {...meta}>
    <Components.Container>
      <Components.H1>КОМАНДА проекта ПИРОГОВСКАЯ 14</Components.H1>
      <Container>
        {articles.slice(0, 2).map((article, index) => (
          <Article {...article} key={index} />
        ))}
      </Container>
      <Container style={{ justifyContent: "center" }}>
        <Article {...articles[2]} size="xl" />
      </Container>

      <Title>Подрядчик и финансовый партнёр</Title>
      <Logo src="/static/team/partner.png" />
      <Description
        dangerouslySetInnerHTML={{
          __html: `STRABAG SE&nbsp;&mdash; европейский концерн по&nbsp;оказанию высокотехнологичных строительных услуг, занимающий ведущее место по&nbsp;внедрению инноваций и&nbsp;размерам капитала.`,
        }}
      />
      <Description
        dangerouslySetInnerHTML={{
          __html: `С&nbsp;1991 года концерн работает на&nbsp;территории Российской Федерации и&nbsp;в&nbsp;качестве генподрядчика возвел более 3,5&nbsp;млн. кв. м. жилых, административных, торговых и&nbsp;промышленных площадей.`,
        }}
      />
      <Title style={{ marginTop: 60, marginBottom: 16 }}>Партнёры</Title>
      <Container style={{ marginBottom: 60, maxWidth: 950 }}>
        {partners.map((partner) => (
          <Partner
            key={partner.title}
            href={partner.url}
            target="_blank"
            rel="nofollow"
          >
            <img src={partner.img} alt={partner.title} title={partner.title} />
          </Partner>
        ))}
      </Container>
    </Components.Container>
  </Layout>
);

export default TeamScreen;
